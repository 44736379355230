<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        Trésorerie > Plan de décaissements
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Plan de décaissements
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <table class="table tablestyle_3 table-striped">
      <thead>
        <tr>
          <th style="width:12%;"></th>
          <template v-for="n in long">
            <th :key="n"
                style="width:12%;"
                class="text-center">{{ Number(annees) +Number(n)-1 }}</th>
          </template>
        </tr>
      </thead>
      <tbody v-if="loaded==true">
        <tr>
          <td class="th-blue"
              style="width:12%;">Décomptes</td>
          <template v-for="(dec,dkey) in tresorerie.donnees.decompte">
            <td :key="dkey"
                class="text-right">{{ Math.round(dec).toLocaleString() }}</td>
          </template>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="th-blue"
              style="width:12%;">Décomptes</td>
          <td :key="dkey"
              class="text-right">0</td>
          
        </tr>
      </tbody>
    </table>
    <div class="row mt-4">
      <div class="col-9 mx-auto"
           v-if="loaded==true">
        <div style="width: 800px; margin: auto">
          <BarChart :chartdata="randomChart1"
                    :options="optionsChart1" />
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import BarChart from "@/components/shared/BarChart"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"

export default ({
  name:"PlanDecaissements",
  props:["extYear"],
  components:{
    BarChart,
    Notif
  },
  data:()=>({
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
    },
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    mouvement_entrant:"",
    mouvement_sortant:"",
    form:form({
      source:"",
      mouvement_entrant:0,
      mouvement_sortant:0,
      date:"",
      observation:"",
    }),
    long:0,
    loaded:false,
    annees:0,
    dettes:[],
    sorties:[],
    entrees:[],
    solde_tresorerie:[],
    randomChart1: {
      labels: [new Date().getFullYear()],
      datasets: [
        {
          label: "Décomptes",
          data: [30, 20, 40, 50, 5],
          backgroundColor: ["#016677"]
        },
      ]
    },
    optionsChart1: {
      responsive: true,
      maintainAspectRatio: false
    },
  }),
  watch:{
    tresorerie(){
      if (this.tresorerie.donnees) {
        this.long = this.tresorerie.donnees.banque.length
        this.annees= this.annee_debut.split("-")
        this.annees= this.annees[0]
        if (this.tresorerie.donnees.decompte) {
          this.sorties=[]
          this.solde_tresorerie=[]
          this.randomChart1.labels=[]
          this.randomChart1.datasets[0].data=[]
          this.randomChart1.datasets[0].backgroundColor=[]

          for (let index = 0; index < this.tresorerie.donnees.decompte.length; index++) {
            this.sorties.push(this.tresorerie.donnees.decompte[index])
            this.randomChart1.labels.push(Number(this.annees)+index)
            this.randomChart1.datasets[0].data.push(this.sorties[index])
            this.randomChart1.datasets[0].backgroundColor.push("#016677")
          }
          this.loaded=true
        }
        console.log(this.annees)
      }
    },
  },
  created(){
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    this.year.annee_debut= this.annee_debut
    this.year.annee_fin= this.annee_fin
    this.loadTresorerie(this.year)
  },
  computed: {
    ...mapGetters(["tresorerie","msgFailTresorerie",])
  },
  methods: {
    ...mapActions(["loadTresorerie",]),
    ...mapMutations(["setTresorerie","setMsgSuccessTresorerie","setMsgFailTresorerie","setEngagementFinancier","setReponseError"]),
    changeYear() {
      this.loaded=false
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin
      this.setTresorerie("")
      this.loadTresorerie(this.year)
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.-^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
            splinter[index] = ""
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
  }
})
</script>
